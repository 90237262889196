<template>
  <v-row>
    <v-col cols="12" :class="`col-${strColBreakpoint}-${iColBreakpoint}`">
      <v-card ref="cardInputRef" outlined flat>
        <v-file-input
          ref="fileInputRef"
          outlined
          dense
          color="black"
          prepend-icon=""
          hide-input
          @change="selectFile($event)"
        ></v-file-input>

        <v-row align="center" justify="space-between">
          <v-col cols="6">
            <p class="kda-ts-12pt nunito main-bw-200--text pl-3 line-clamp-1">
              {{ m_strFileName !== '' ? m_strFileName : m_strPlaceholder }}
            </p>
          </v-col>

          <v-col cols="6" class="d-flex justify-end">
            <v-btn
              class="kda-ts-12pt nunito main-bw-300--text text-capitalize"
              elevation="0"
              outlined
              @click="triggerFile"
            >
              {{ m_strBtn }}
            </v-btn>
          </v-col>
        </v-row>
      </v-card>

      <p :class="`${m_bWarningShow ? 'error' : 'transparent'}--text my-0 kda-ts-12pt ml-3`">
        {{ m_strWarningText }}&nbsp;
      </p>
    </v-col>
  </v-row>
</template>

<script>
import * as CONFIG from '@/config/config.json';

export default {
  name: 'KDADroppableFileUpload',

  props: {
    strName: {
      type: String,
      default: 'File'
    },
    textColor: {
      type: String,
      default: 'Grey'
    },
    bFirstTrySubmit: {
      type: Boolean,
      default: false
    },
    strColBreakpoint: {
      type: String,
      default: 'md'
    },
    iColBreakpoint: {
      type: [Number, String],
      default: 6
    }
  },

  data() {
    return {
      m_strFileName: '',
      bWarningShow: false,
      m_strWarningText: `${this.strName} is required`
    };
  },

  computed: {
    m_bWarningShow: {
      get() {
        return this.bFirstTrySubmit || this.bWarningShow;
      },
      set(value) {
        this.bWarningShow = value;
      }
    },
    m_strBtn() {
      return this.$vuetify.lang.t('$vuetify.kda.careers.details.select');
    },
    m_strPlaceholder() {
      return this.$vuetify.lang.t('$vuetify.kda.careers.details.drag');
    }
  },

  mounted() {
    this.$refs.cardInputRef.$el.addEventListener('dragover', function (ev) {
      ev.preventDefault();
      ev.stopPropagation();
    });

    this.$refs.cardInputRef.$el.addEventListener(
      'drop',
      function (ev) {
        ev.preventDefault();

        if (ev.dataTransfer.items) {
          const item = ev.dataTransfer.items[0];
          if (item.kind === 'file') {
            this.selectFile(item.getAsFile());
          }
        } else {
          const file = ev.dataTransfer.files[0];
          this.selectFile(file);
        }
      }.bind(this)
    );
  },

  methods: {
    selectFile(event) {
      if (event) {
        if (this.validate(event)) {
          this.$emit('selectFile', event);
          this.m_strFileName = event.name;
        }
      }
    },
    triggerFile() {
      this.$refs.fileInputRef?.$el.getElementsByTagName('input')?.[0]?.click();
    },
    validate(objFile) {
      if (!(objFile instanceof File)) {
        this.m_bWarningShow = true;
        this.m_strWarningText = `${this.strName} is required`;
        return false;
      } else if (!CONFIG.acceptable_file_mime.includes(objFile.type)) {
        this.m_bWarningShow = true;
        this.m_strWarningText = 'File extension can only be .pdf or .docx';
        return false;
      } else if (objFile.size > CONFIG.file_max_size_mb * 1024 * 1024) {
        this.m_bWarningShow = true;
        this.m_strWarningText = 'Max file size is 20MB.';
        return false;
      } else {
        this.m_bWarningShow = false;
        this.m_strWarningText = '';
        return true;
      }
    }
  }
};
</script>

<style scoped lang="scss">
.v-btn--outlined,
.v-sheet--outlined {
  border: thin solid #d9d9d9;
  background-color: white;
}
</style>
